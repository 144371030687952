$(document).ready(function () {
    $('.price_content .price_razdel .razdel_content').hide();
    $('.price_content .price_razdel .razdel_title').click(function () {
        if($(this).hasClass('active')) {
            $(this).removeClass("active").next(".razdel_content").slideUp(600);
            $(this).find('span').removeClass('opened');
        } else {
            $(this).next('.razdel_content').slideDown(600);
            $(this).removeClass('active');
            $(this).addClass("active").next(".razdel_content").slideDown(600);
            $(this).find('span').addClass('opened');
        }
        return false;
    })
});