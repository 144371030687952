// var sliders = {
//     'rec': {
//       slidesToShow: 3,
//       slidesToScroll: 3,
//       arrows: true,
//       slide: '.catalog-item',
//       prevArrow: '<button class="icon-left prev"></button>',
//       nextArrow: '<button class="icon-right next"></button>',
//       responsive: [
//           {
//             breakpoint: 1199.98,
//             settings: {
//               slidesToShow: 2,
//               slidesToScroll: 2
//             }
//           },
//           {
//               breakpoint: 767.98,
//               settings: {
//                 slidesToShow: 1,
//                 slidesToScroll: 1
//               }
//           }
//       ]
//     },
//     'rec-l': {
//         dots: false,
//         arrows: false,
//         slidesToShow: 4,
//         slidesToScroll: 4,
//         slide: '.catalog-item',
//         adaptiveHeight: true,
//         responsive: [
//             {
//               breakpoint: 1200,
//               settings: {
//                 slidesToShow: 3,
//                 slidesToScroll: 3,
//               }
//             },
//             {
//                 breakpoint: 768,
//                 settings: {
//                   slidesToShow: 2,
//                   slidesToScroll: 2,
//                 }
//             },
//             {
//                 breakpoint: 576,
//                 settings: {
//                   slidesToShow: 1,
//                   slidesToScroll: 1,
//                 }
//             }
//           ]
//     },
//     'cat-l': {
//       dots: false,
//       arrows: true,
//       prevArrow: '<button class="icon-left prev"></button>',
//       nextArrow: '<button class="icon-right next"></button>',
//       slidesToShow: 4,
//       slidesToScroll: 4,
//       infinite: false,
//       slide: '.catalog-item',
//       adaptiveHeight: true,
//       responsive: [
//           {
//             breakpoint: 1200,
//             settings: {
//               slidesToShow: 3,
//               slidesToScroll: 3,
//             }
//           },
//           {
//               breakpoint: 768,
//               settings: {
//                 slidesToShow: 2,
//                 slidesToScroll: 2,
//               }
//           },
//           {
//               breakpoint: 576,
//               settings: {
//                 slidesToShow: 1,
//                 slidesToScroll: 1,
//                 arrows: false
//               }
//           }
//       ]
//     }
//   };
$(document).ready(function(){
    $('.default-slider').slick({
        prevArrow: '<button class="icon-left prev"></button>',
        nextArrow: '<button class="icon-right next"></button>',
        dots: true
    });

    $('.before-after-slider').slick({
      prevArrow: '<button class="icon-left prev"></button>',
      nextArrow: '<button class="icon-right next"></button>',
      slidesToShow: 3,
      slidesToScroll: 3,
      responsive: [
        {
          breakpoint: 768,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2
            }
        },
        {
          breakpoint: 576,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
        }

      ]
    })

    $('.slider-blog').slick({
        slidesToShow: 3,
        slidesToScroll: 3,
        prevArrow: $('.prev-arrow_blog'),
        nextArrow: $('.next-arrow_blog'),
        responsive: [
          {
            breakpoint: 991,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2
            }
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          },
          {
            breakpoint: 576,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
    });

    $('.top-services-slider').slick({
      slidesToShow: 3,
      slidesToScroll: 3,
      prevArrow: $('.prev-arrow_top_serv'),
      nextArrow: $('.next-arrow_top_serv'),
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2
          }
        },
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 576,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
        
      ]

    });

    $('ul.slick-dots li>button').empty();
    $('.tax-slider__wrapper').slick({
      slidesToShow: 3,
      slidesToScroll: 3,
      prevArrow: $('.prev-arrow_tax'),
      nextArrow: $('.next-arrow_tax'),
      responsive: [
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2
          }
        },
        {
          breakpoint: 576,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
  });
  });


  

$('.slider-arrows .arrow').click(function(){
    var elem    = $(this),
        dir     = elem.data('direction') == 'prev' ? 'Prev' : 'Next',
        parent  = elem.parents('.section-title'),
        slider  = parent.siblings('.slick-slider');
  
      if(slider.length == 0){
        slider = parent.siblings().find('.slick-slider');
      }
  
    slider.slick('slick' + dir);
  })