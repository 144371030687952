var animations = {
    // 'animateServices' : function(){
    //     var timer = 0,
    //         slides = $('section.top-servises .top-services-slider .slick-slide');

    //     slides.each(function(){
    //         var elem = $(this);

    //         setTimeout(() => {
    //             elem.addClass('animate');
    //         }, timer);

    //         timer += 200;

    //     });

    //     delete animations['animateServices'];
    // },
    'animateBlog' : function(){
        var timer = 0,
            items = $('.blog-items .item-post');

        items.each(function(){
            var elem = $(this);

            setTimeout(() => {
                elem.addClass('animate');
            }, timer);

            timer += 200;

        });

        delete animations['animateBlog'];
    },
    'animateBlogHome' : function(){
        var timer = 0,
            items = $('section.blog_part .slider-blog .slick-slide');

        items.each(function(){
            var elem = $(this);

            setTimeout(() => {
                elem.addClass('animate');
            }, timer);

            timer += 200;

        });

        delete animations['animateBlogHome'];
    },
    'animatePrice' : function(){
        var timer = 0,
            items = $('.price_content .price_razdel');

        items.each(function(){
            var elem = $(this);

            setTimeout(() => {
                elem.addClass('animate');
            }, timer);

            timer += 200;

        });

        delete animations['animatePrice'];
    }
}

function checkScroll(){
    var sections = $('.animate-block'),
        scrolled = $(window).scrollTop();

    sections.each(function(){
        var elem = $(this),
            func = elem.data('func'),
            height = $(window).height() / 2;
            offset = elem.offset().top - height;

        if(offset <= scrolled){
            elem.addClass('animate');
            elem.removeClass('animate-block');
            
            if(animations[func]){
                animations[func]();
            }

        }
    })

};

$(document).ready(function () {    
    checkScroll();
    
    $(window).on('resize', checkScroll);
    $(document).on('scroll', checkScroll);
});