$(document).on('click', '.arrows div', function() {
    var elem = $(this),
        index = elem.index(),
        parent = elem.parent(),
        slider = $('.' + parent.data('slider'));

    if(index){
        slider.slick('slickNext');
    }else{
        slider.slick('slickPrev');
    }
});
