function startAmimate() {
    let title = $('section.hero .title');
    title.addClass('animate');
}
$(document).ready(function () {
    startAmimate();
});



